<template>
  <v-container fluid>
    <PageHeaderSlot :showBottomLeftAndRightRow="true" :showBackButton="true" :isLoading="submitLoading || fetching">
    </PageHeaderSlot>
    <v-row class="mt-6">
      <v-col cols="12">
        <h3>{{ $validate.DataValid(id) ? '假期申請詳細' : '新增假期申請' }}</h3>
      </v-col>
      <v-col cols="12">
        <v-form ref="leave-form" @submit.prevent="formSubmit">
          <v-card class="mb-8">
            <v-card-text>
              <v-row>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="staff_id"
                    label="員工"
                    :required="true"
                    :options="staffList"
                    @selectChange="changeStaff"
                    :disabled="fetching"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="absent_type"
                    label="假期類別"
                    :required="true"
                    :options="absentTypeList"
                    @selectChange="changeAbsentType"
                    :disabled="fetching"
                  />
                </v-col>
              </v-row>
              <div v-show="$validate.DataValid(staff_id) && $validate.DataValid(absent_type)">
                <v-row v-show="dayCountArr.includes(absent_type)">
                  <v-col>
                    <h4>{{ remainingLeave }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="12">
                    <FormControl
                      inputType="select"
                      :inputValue.sync="absentTime"
                      label="種類"
                      :required="true"
                      :options="absentTimeList"
                      @selectChange="changeAbsentTime"
                      :disabled="fetching"
                    />
                  </v-col>
                  <v-col md="6" cols="12" v-show="absentTime === 'full_day'">
                    <FormControl
                      inputType="dateRangePicker"
                      :inputValue.sync="absentDateArr"
                      label="請假日期"
                      :required="absentTime === 'full_day'"
                      :disabled="fetching"
                      :minDay="minDay"
                      :maxDay="maxDay"
                      @pickerInput="changeDateRange"
                      @dateRangeDialog="updateDateRange"
                    />
                  </v-col>
                  <v-col md="6" cols="12" v-show="['am', 'pm'].includes(absentTime)">
                    <FormControl
                      inputType="datePicker"
                      :inputValue.sync="absentDate"
                      label="請假日期"
                      :required="['am', 'pm'].includes(absentTime)"
                      :disabled="fetching"
                      :minDay="minDay"
                      :maxDay="maxDay"
                      @pickerInput="changeDate"
                      @dateDialog="updateDate"
                    />
                  </v-col>
                  <v-col cols="12" v-show="['annual_leave', 'sick_leave', 'no_pay_leave'].includes(absent_type)">
                    <FormControl
                      inputType="textarea"
                      :inputValue.sync="reason"
                      label="原因"
                      :required="['annual_leave', 'sick_leave', 'no_pay_leave'].includes(absent_type)"
                      :disabled="fetching"
                    />
                  </v-col>

                  <v-col md="6" cols="12" v-show="$validate.DataValid(id)">
                    <FormControl
                      inputType="select"
                      :inputValue.sync="approveStatus"
                      label="申請狀態"
                      :required="$validate.DataValid(id)"
                      :options="approveStatusList"
                      :disabled="fetching"
                    />
                  </v-col>
                  <v-col md="6" cols="12" v-show="$validate.DataValid(id)">
                    <FormControl inputType="string" :inputValue.sync="remark" label="備註" :disabled="fetching" />
                  </v-col>

                  <v-col cols="12" v-show="absent_type === 'sick_leave'">
                    <h4>病假紙</h4>
                    <ImageUploader
                      :singleImage="false"
                      :imgList.sync="medical_certificate"
                      :uploadText="'上載病假紙'"
                      :acceptFormat="'imageWithPDF'"
                      :disableUpload="submitLoading || fetching"
                    ></ImageUploader>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="submitLoading">
            儲存
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ImageUploader from '@/components/ImageUploader.vue'
import FormControl from '@/components/form/FormControl'

export default {
  name: 'AdminLeaveDetail',
  components: {
    FormControl,
    ImageUploader,
  },
  data() {
    return {
      submitLoading: false,
      fetching: false,

      staff_id: '',
      absent_type: '',
      absentTime: '',
      absentDate: '',
      absentDateArr: [],
      reason: '',
      medical_certificate: [],
      approveStatus: '',
      remark: '',

      staffList: [],
      remainingLeaveCount: 0,
      remainingLeave: '',
      minDay: '',
      maxDay: '',

      submitSuccess: false,

      isDateUpdated: false,
    }
  },
  computed: {
    id: function () {
      return this.$route.params.id
    },
    absentTypeList: function () {
      return this.$absentTypeList
    },
    absentTimeList: function () {
      return this.$absentTimeList
    },
    approveStatusList: function () {
      return this.$approveStatusList
    },
    dayCountArr: function () {
      return ['annual_leave', 'sick_leave', 'comp_leave']
    },
  },
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getStaffList() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_staff', payload)
        this.$func.log('-----Get Staff List-----')
        this.$func.log(result)

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          temp.push({
            value: resultData.id,
            text: `${resultData.name} (${resultData.email})`,
          })
        }

        this.staffList = temp
      } catch (error) {
        this.$func.log('-----Get Staff fail-----')
        this.$func.log(error)

        let msg = ''

        if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      }
    },
    async getAbsentDetail() {
      let absentError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_absent_data_by_id', payload)
        this.$func.log('-----Get Absent Detail-----')
        this.$func.log(result)

        this.staff_id = result.staff_id
        this.absent_type = result.absent_type
        this.absentTime = result.time

        this.reason = result.reason
        this.medical_certificate = result.medical_certificate
        this.approveStatus = result.approve_status
        this.remark = result.remark

        if (this.absentTime === 'full_day') {
          this.absentDateArr = [result.start_date, result.end_date]
        } else {
          this.absentDate = result.start_date
        }

        await this.searchRemainingDay(true)

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Absent Detail Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          absentError = true
          message = '沒有權限'
        } else {
          absentError = true
        }
      }

      if (absentError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'adminLeave',
        })
      }
    },
    async searchRemainingDay(updateMinMaxDay = false, selectStartDate = '') {
      this.remainingLeaveCount = 0
      this.remainingLeave = ''

      if (this.$validate.DataValid(this.staff_id) && this.$validate.DataValid(this.absent_type)) {
        if (this.dayCountArr.includes(this.absent_type)) {
          if (!this.fetching) {
            this.fetching = true

            let today = this.$func.formatDate(new Date())

            try {
              const payload = {
                verify_token: this.getAdminToken(),
                staff_id: parseInt(this.staff_id),
              }

              /* if (this.$validate.DataValid(this.id)) {
                payload.exclude_id = parseInt(this.id)
              } */

              if (this.$validate.DataValid(selectStartDate)) {
                payload.search_date = selectStartDate
              } else {
                if (this.absentTime === 'full_day') {
                  payload.search_date = this.absentDateArr.length > 0 ? this.absentDateArr[0] : today
                } else {
                  payload.search_date = this.$validate.DataValid(this.absentDate) ? this.absentDate : today
                }
              }

              if (this.absent_type === 'annual_leave') {
                payload.search_al = true
              } else if (this.absent_type === 'sick_leave') {
                payload.search_sl = true
              } else if (this.absent_type === 'comp_leave') {
                payload.search_comp_day = true
              }

              const result = await this.$XHR.api('cms_search_remaining_leave_day', payload)
              this.$func.log('-----Get Remaining Day-----')
              this.$func.log(result)

              if (this.absent_type === 'annual_leave') {
                this.remainingLeaveCount = result.annual_leave
              } else if (this.absent_type === 'sick_leave') {
                this.remainingLeaveCount = result.sick_leave
              } else if (this.absent_type === 'comp_leave') {
                this.remainingLeaveCount = result.annual_comp_day
              }

              if (updateMinMaxDay) {
                /* if (today > result.start_date) {
                  this.minDay = today
                } else {
                  this.minDay = result.start_date
                } */
                this.minDay = result.start_date

                this.maxDay = result.end_date
              }

              const arr = this.absentTypeList.filter(obj => {
                return obj.value === this.absent_type
              })

              this.remainingLeave = `尚餘${arr[0].text} (${result.start_date}至${result.end_date})：${this.remainingLeaveCount}日`
            } catch (error) {
              this.$func.log('-----Get Remaining Day Fail-----')
              this.$func.log(error)

              const arr = this.absentTypeList.filter(obj => {
                return obj.value === this.absent_type
              })
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: `取得剩餘${arr[0].text}失敗，請稍侯再試`,
                type: 'error',
                refresh: false,
                redirect: '',
              })
            } finally {
              this.fetching = false
            }
          }
        }
      }
    },
    initializeDateModal() {
      this.isDateUpdated = false
      this.absentDate = ''
      this.absentDateArr = []
      this.minDay = ''
      // this.minDay = this.$func.formatDate(new Date())
      this.maxDay = ''
    },
    changeStaff(id) {
      this.initializeDateModal()
      this.searchRemainingDay()
    },
    changeAbsentType(type) {
      if (this.$validate.DataValid(this.id)) {
        if (type === 'sick_leave') {
          this.approveStatus = 'approved'
        }
      }
      this.initializeDateModal()
      this.searchRemainingDay()
    },
    changeAbsentTime(time) {
      this.initializeDateModal()
    },
    changeDateRange(arr) {
      if (this.$validate.DataValid(arr)) {
        this.isDateUpdated = true
        if (arr.length === 1) {
          this.searchRemainingDay(true, arr[0])
        }
      } else {
        // does not select anything
        this.initializeDateModal()
      }
    },
    changeDate(date) {
      this.isDateUpdated = true
    },
    updateDateRange(action) {
      if (action === 'confirm') {
        if (this.absentDateArr.length > 0) {
          if (this.absentDateArr.length === 2) {
            if (this.absentDateArr[0] === this.absentDateArr[1]) {
              this.absentDateArr.splice(this.absentDateArr.length - 1, 1)
            }
          }
        } else {
          if (this.isDateUpdated) {
            this.searchRemainingDay()
          }
          this.initializeDateModal()
        }
      } else if (action === 'cancel') {
        if (this.isDateUpdated) {
          if (this.absentDateArr.length > 0) {
            this.searchRemainingDay(true)
          } else {
            this.searchRemainingDay()
          }
        }
      } else if (action === 'clear') {
        this.initializeDateModal()
        this.searchRemainingDay()
      }

      this.isDateUpdated = false
    },
    updateDate(action) {
      if (action === 'confirm') {
        if (this.$validate.DataValid(this.absentDate)) {
          if (this.isDateUpdated) {
            this.searchRemainingDay()
          }
        } else {
          this.initializeDateModal()
        }
      } else if (action === 'cancel') {
        if (this.isDateUpdated) {
          if (this.$validate.DataValid(this.absentDate)) {
            this.searchRemainingDay(true)
          } else {
            this.searchRemainingDay()
          }
        }
      } else if (action === 'clear') {
        this.initializeDateModal()
        this.searchRemainingDay()
      }

      this.isDateUpdated = false
    },
    formSubmit: async function () {
      if (!this.fetching && !this.submitLoading) {
        this.submitLoading = true

        const isValid = this.$refs['leave-form'].validate()

        if (!isValid) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.submitLoading = false
          return
        }

        /* if (this.dayCountArr.includes(this.absent_type)) {
          let dayCount = 0

          if (this.absentTime === 'full_day') {
            let startDate = ''
            let endDate = ''

            if (this.absentDateArr.length === 1) {
              startDate = this.absentDateArr[0]
              endDate = this.absentDateArr[0]
            } else {
              startDate = this.absentDateArr[0]
              endDate = this.absentDateArr[1]
            }

            dayCount = this.$func.calculateTotalDay(startDate, endDate)
          } else {
            dayCount = 0.5
          }

          if (dayCount > this.remainingLeaveCount) {
            let msg = ''
            if (this.absent_type === 'annual_leave') {
              msg = '沒有足夠年假'
            } else if (this.absent_type === 'sick_leave') {
              msg = '沒有足夠病假'
            } else if (this.absent_type === 'comp_leave') {
              msg = '沒有足夠補假'
            }

            if (this.$validate.DataValid(msg)) {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: msg,
                type: 'error',
                refresh: false,
                redirect: '',
              })
            }

            this.submitLoading = false
            return
          }
        } */

        try {
          const payload = {
            verify_token: this.getAdminToken(),
            staff_id: parseInt(this.staff_id),
            absent_type: this.absent_type,
            time: this.absentTime,
            remark: this.remark,
          }

          if (this.absentTime === 'full_day') {
            payload.start_date = this.absentDateArr[0]
            payload.end_date = this.absentDateArr.length >= 2 ? this.absentDateArr[1] : this.absentDateArr[0]
          } else {
            payload.start_date = this.absentDate
            payload.end_date = this.absentDate
          }

          if (['annual_leave', 'sick_leave', 'no_pay_leave'].includes(this.absent_type)) {
            payload.reason = this.reason

            if (this.absent_type === 'sick_leave') {
              payload.medical_certificate = this.medical_certificate
            } else {
              payload.medical_certificate = []
            }
          } else {
            payload.reason = ''
            payload.medical_certificate = []
          }

          let key = ''
          if (this.$validate.DataValid(this.id)) {
            key = 'cms_update_absent'
            payload.id = parseInt(this.id)
            payload.approve_status = this.approveStatus
          } else {
            key = 'cms_apply_absent'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('--- update absent data ---')
          this.$func.log(result)

          this.submitSuccess = true

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'adminLeave' },
          })
          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('--- update absent data fail ---')
          this.$func.log(error)

          let msg = '儲存失敗'

          if (error.data === 'admin verification fail') {
            msg = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'staff not found') {
            msg = '員工不存在'
          } else if (error.data === 'start date cannot earlier than end date') {
            msg = '開始日期不可早於結束日期'
          } else if (error.data === 'half day leave cannot select multiple days') {
            msg = '只可選擇一天假期'
          } else if (error.data === 'date overlap') {
            let date = ''
            if (error['overlap_date'].length === 1) {
              date = error['overlap_date'][0]
            } else if (error['overlap_date'].length === 2) {
              if (error['overlap_date'][0] === error['overlap_date'][1]) {
                date = error['overlap_date'][0]
              } else {
                date = error['overlap_date'].join('至')
              }
            }

            if (this.$validate.DataValid(date)) {
              msg = `你已申請於${date}請假`
            }
          } else if (error.data === 'remaining leave is not enough') {
            msg = '沒有足夠假期'
          }

          this.submitLoading = false
          this.submitSuccess = false
          this.setDialogMessage({
            message: msg,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.getStaffList()

      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getAbsentDetail()
      }
    }
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

   /*  if (!this.submitSuccess) {
      removeList = removeList.concat(this.medical_certificate)
    } */

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: removeList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }
  },
}
</script>