<template>
  <v-row>
    <v-col
      :xl="singleImage ? '4' : '12'"
      :lg="singleImage ? '4' : '12'"
      :md="singleImage ? '6' : '12'"
      :sm="singleImage ? '6' : '12'"
      cols="12"
    >
      <div class="image-upload-display">
        <v-img
          v-if="imgList.length <= 0"
          :src="require('@/assets/empty-image.svg')"
          :max-height="150"
          :max-width="250"
        ></v-img>

        <div v-for="(img, index) of imgList" :key="index">
          <v-img
            class="preview-img"
            :src="getImage(img)"
            :max-height="150"
            :max-width="250"
            @click="previewImg(index)"
          ></v-img>
          <v-btn depressed small text color="error" class="mt-1" @click="removeImage(index)" :disabled="disableUpload"
            >刪除</v-btn
          >
        </div>
      </div>
    </v-col>
    <v-col
      :xl="singleImage ? '4' : '12'"
      :lg="singleImage ? '4' : '12'"
      :md="singleImage ? '6' : '12'"
      :sm="singleImage ? '6' : '12'"
      cols="12"
    >
      <v-file-input
        v-show="false"
        :id="fileInputKey"
        :accept="fileFormatStr"
        outlined
        @change="getUploadFile($event, fileInputKey)"
        :disabled="uploading || disableUpload"
      ></v-file-input>
      <v-btn
        color="primary"
        depressed
        @click="triggerUploadFile(fileInputKey)"
        :disabled="disableUpload"
        :loading="uploading"
      >
        {{ uploadText }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ImageUploader',
  props: {
    singleImage: {
      type: Boolean,
      default: true,
      require: true,
    },
    imgList: {
      type: Array,
      default: () => [],
      require: true,
    },
    uploadText: {
      type: String,
      default: '上載',
      require: false,
    },
    fileInputKey: {
      type: String,
      default: 'upload_file',
      require: true,
    },
    acceptFormat: {
      type: String,
      default: 'image',
      require: true,
    },
    disableUpload: {
      require: false,
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    fileFormatStr: '',
    fileFormatArr: [],
  }),
  methods: {
    triggerUploadFile(id) {
      document.getElementById(id).click()
    },
    async getUploadFile(file, id) {
      if (file !== undefined && file !== null) {
        const check = this.$validate.validateFileInput(file, this.acceptFormat, this.fileFormatArr)
        if (this.$validate.DataValid(check)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: check,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } else {
          this.$store.dispatch('setLoading', true)

          let postData = {
            upload_image: { forceJPG: true },
            file,
          }

          if (file.type === 'application/pdf') {
            postData = {
              upload_document: {},
              file,
            }
          } else if (file.type === 'video/mp4' || file.type === 'video/webm') {
            postData = {
              upload_video: {},
              file,
            }
          }

          this.$XHR
            .post(postData, progress => {
              this.$store.dispatch('controlUploadAlert', {
                show: true,
                progress: this.$func.formatUploadProgress(progress.loaded, progress.total),
              })
            })
            .then(result => {
              this.$store.dispatch('setLoading', false)
              this.$store.dispatch('controlUploadAlert')
              this.$func.log('--- upload success ---')
              this.$func.log(result)

              if (this.singleImage) {
                if (this.imgList.length > 0) {
                  this.$store.dispatch('insertRemovedImage', this.imgList[0])
                }

                this.$set(this.imgList, 0, result)
              } else {
                this.imgList.push(result)
              }

              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: '上傳成功',
                type: 'success',
                refresh: false,
                redirect: '',
              })
            })
            .catch(error => {
              this.$store.dispatch('controlUploadAlert')
              this.$store.dispatch('setLoading', false)
              this.$func.log('--- upload fail ---')
              this.$func.log(error)
              if (this.$validate.DataValid(error)) {
                this.$store.dispatch('toggleAlertMessage', {
                  show: true,
                  message: error[1],
                  type: 'error',
                  refresh: false,
                  redirect: '',
                })
              } else {
                this.$store.dispatch('toggleAlertMessage', {
                  show: true,
                  message: '上傳失敗',
                  type: 'error',
                  refresh: false,
                  redirect: '',
                })
              }
            })
        }
      }
    },
    getImage(img) {
      if (img.indexOf('pdf') !== -1) {
        return require('@/assets/file-pdf-box.svg')
      } else if (img.indexOf('mp4') !== -1 || img.indexOf('webm') !== -1) {
        return require('@/assets/video.svg')
      }

      return this.$mediaPath + img
    },
    previewImg(index) {
      if (this.$validate.DataValid(this.imgList[index])) {
        window.open(this.$mediaPath + this.imgList[index])
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '檔案不存在',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    removeImage(index) {
      this.$store.dispatch('insertRemovedImage', this.imgList[index])

      this.imgList.splice(index, 1)
    },
  },
  watch: {
    acceptFormat: {
      handler(val) {
        const format = this.$func.getAcceptFileFormat(val)
        this.fileFormatStr = format.formatStr
        this.fileFormatArr = format.formatArr
      },
      immediate: true,
    },
  },
  computed: {
    uploading() {
      return this.$store.getters.isLoading
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-img {
  &:hover {
    cursor: pointer;
  }
}

.image-upload-display {
  display: flex;
  overflow-x: auto;
  align-items: center;

  & > div:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
